<template>
  <v-card>
    <TopKeystoneToolbar
      entity-type="holding"
      v-model="search"
      :open-new="openNewHolding"
      v-on:refresh="refresh"
    >
    </TopKeystoneToolbar>

    <HoldingForm
      v-model="dialogForm"
      :is-edit-form.sync="isEditForm"
      :entity-id="currentHoldingId"
      v-on:refresh="refresh"
      :default-entity="defaultEntity"
      ref="holdingForm"
    >
    </HoldingForm>

    <div class="entity-view-div">
      <v-data-table
        :headers-length="5"
        :loading="$store.getters.getLoadingHoldings"
        :items="holdings"
        :headers="headers"
        :options.sync="pagination"
        :server-items-length="totalItems"
        @update:options="callApi"
        :footer-props="{
          itemsPerPageOptions: rowPerPageItem
        }"
      >
        <template v-slot:item="props">
          <tr>
            <td>
            {{ props.item.id }}
          </td>
          <td>
            <strong>
              {{ props.item.name }}
            </strong>
          </td>
          <td>
            <v-icon
              :color="props.item.has_fit_score ? 'green' : 'red'"
            >
              power_settings_new
            </v-icon>
          </td>
          <td>
            <v-icon small>
                launch
              </v-icon>
              <a @click="goToCompany(props.item)"> Companies of {{props.item.name}}</a>
            </td>
            <td>
              <v-icon small>
                launch
              </v-icon>
              <a @click="goToSeat(props.item)"> Seats of {{props.item.name}}</a>
            </td>
            <td>
              <v-btn icon>
                <v-icon @click="openEditHolding(props.item.id)">
                  edit
                </v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>
  </v-card>
</template>

<script>
import store from '../../../store'
import { keystoneV2 } from '../../../store/modules/keystoneV2'
import * as keystoneV2Utils from '../../../utils/keystoneV2Utils'
import TopKeystoneToolbar from '@/components/KeystoneV2/TopKeystoneToolbar'
import { rulesMixin } from '@/mixins/rulesMixin'
import HoldingForm from '@/components/KeystoneV2/Form/HoldingForm'
import { openFormOptionMixin } from '@/mixins/openFormOptionMixin'
import HoldingModel from '@/models/Keystone_v2/HoldingModel'
import { ROW_PER_PAGE_ITEM } from '@/components/KeystoneV2/commonConstantKeystone'
import { keystoneTabViewMixin } from '@/mixins/keystoneTabViewMixin'
import { getEntityApiPayload } from '@/components/KeystoneV2/payloadHelperKeystone'
import _ from 'lodash'

// load the store module dynamically only when needed
if (!store.state.keystoneV2) store.registerModule('keystoneV2', keystoneV2)

export default {
  name: 'HoldingView.vue',
  components: { HoldingForm, TopKeystoneToolbar },
  mixins: [rulesMixin, openFormOptionMixin, keystoneTabViewMixin],
  data: function () {
    return {
      headers: [
        { text: 'Internal ID', value: 'id', width: '15px', sortable: false },
        { text: 'Holding name', value: 'name' },
        { text: 'Fit Score', value: 'has_fit_score' },
        { text: 'Companies', sortable: false },
        { text: 'Seats', sortable: false },
        { text: 'Action', sortable: false }
      ],
      isEditForm: false,
      processingHolding: false,
      search: '',
      dialogForm: false,
      currentHoldingId: null,
      defaultEntity: null,
      entityType: 'holding',
      rowPerPageItem: ROW_PER_PAGE_ITEM,
      pagination: {}
    }
  },
  async mounted () {
    await this.callApiIfRightTab()
    if (this.$store.getters.getEditBilling && this.$store.getters.getEditBillingID !== 0 && this.$route.query.type === 'holding') {
      this.openEditHolding(this.$store.getters.getEditBillingID)
      this.$store.commit('resetEditBilling')
    }
  },
  methods: {
    async callApi () {
      const payload = getEntityApiPayload(this.search, this.pagination, 'holdingId')
      await this.$store.dispatch('getApiHoldings', payload)
    },
    async refresh () {
      // for avoid spam
      if (this.$store.getters.getLoadingHoldings) {
        return
      }
      await this.callApi()
    },
    goToCompany (holding) {
      const option = {
        text: holding.name,
        id: holding.id,
        isParent: true,
        source: 'holding'
      }
      this.$store.commit('setTabSearchCompanies', option)
      const tab = keystoneV2Utils.getTabForEntity('company')
      this.$store.commit('setCurrentTab', tab)
    },
    async goToSeat (holding) {
      const option = {
        text: holding.name,
        id: holding.id,
        isParent: true,
        source: 'holding'
      }
      this.$store.commit('setTabSearchSieges', option)
      const tab = keystoneV2Utils.getTabForEntity('siege')
      this.$store.commit('setCurrentTab', tab)
    },
    /**
     * @param metaHolding {MetaHoldingModel}
     */
    openNewHolding (metaHolding = null) {
      this.isEditForm = false
      this.defaultEntity = new HoldingModel()
      this.defaultEntity.hasInsight = false
      this.$refs.holdingForm.defineMsaTypeItemsForNewForm()

      if (metaHolding) {
        this.defaultEntity.metaHolding = metaHolding
      }
      this.currentHoldingId = null
      this.dialogForm = true
    },
    async openEditHolding (holdingId) {
      this.isEditForm = true
      this.dialogForm = true
      this.currentHoldingId = holdingId
    },
    /**
     * called by open form mixin
     * @param metaHolding
     */
    openNew (metaHolding = null) {
      this.openNewHolding(metaHolding)
    },
    /**
     * called by open form mixin
     * @param holdingId
     */
    openEdit (holdingId) {
      this.openEditHolding(holdingId)
    }
  },
  computed: {
    holdings: function () {
      return this.$store.getters.getHoldings
    },
    totalItems: function () {
      return this.$store.getters.getTotalCountHoldings
    }
  },
  watch: {
    '$store.getters.getTabSearchHoldings': {
      immediate: false,
      deep: true,
      handler: async function (newValue) {
        this.pagination.page = 1
        await this.callApi()
      }
    },
    search: _.debounce(function () {
      this.callApi()
    }, 600)
  }
}
</script>

<style scoped>

</style>
